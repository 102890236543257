import { createSlice } from '@reduxjs/toolkit';

import {
  getCopyName,
  getZonesMapName,
} from '../../../helpers';
import { prepareDataLayers } from '../../../helpers/analysis';
import { SATELLITE_INDEXES } from '../../../helpers/satellite';
import { selectAssets } from '../../field/fieldSelectors';
import {
  selectChanged,
  selectCloned,
  selectDataLayer,
  selectAssetType,
  selectSettings,
} from './createSingleLayerAnalysisSelectors';
import { AssetType } from '../../../helpers/constants/entities/asset';
import { DEFAULT_ZONES_CREATION_SETTINGS } from '../../createAnalysis/helpers/constants/vamap';
import { getUseInterpolatedDataValue } from '../../createAnalysis/helpers/functions/settings';

const initialState = {
  _cloned: false,
  _changed: false,
  assetType: AssetType.satelliteImage,
  dataLayer: {
    satelliteIndex: SATELLITE_INDEXES[0].value,
    satelliteImageUuid: '',
    datasetAttribute: '',
    datasetUuid: '',
  },
  settings: {
    ...DEFAULT_ZONES_CREATION_SETTINGS,
  },
};

export const navigateToSettings = () => (dispatch, getState) => {
  const state = getState();
  const changed = selectChanged(state);
  const cloned = selectCloned(state);
  const settings = selectSettings(state);
  const { useInterpolatedData } = settings;
  let { name } = settings;

  const {
    satelliteIndex,
    satelliteImageUuid,
    datasetUuid,
    datasetAttribute,
  } = selectDataLayer(state);
  const assets = selectAssets(state);
  const [dataLayer] = prepareDataLayers([{
    type: selectAssetType(state),
    index: satelliteIndex,
    satelliteImageUuids: satelliteImageUuid ? [satelliteImageUuid] : [],
    datasetUuid,
    attribute: datasetAttribute,
  }], assets);

  if (changed) {
    const zonesMapName = getZonesMapName(dataLayer);
    name = getCopyName(zonesMapName, cloned);
  }

  dispatch(goToSettings({
    name,
    changed,
    useInterpolatedData: changed || useInterpolatedData == null
      ? getUseInterpolatedDataValue([dataLayer])
      : useInterpolatedData,
  }));
};

export const createSingleLayerAnalysisSlice = createSlice({
  name: 'createSingleLayerAnalysis',
  initialState,
  reducers: {
    goToSettings(state, action) {
      state._changed = false;
      state.settings.name = action.payload.name;
      state.settings.useInterpolatedData = action.payload.useInterpolatedData;
    },
    reset() {
      return initialState;
    },
    goBackFromDataLayers() {
      return initialState;
    },
    setSelectedAssetType(state, action) {
      state.assetType = action.payload;
    },
    setDataLayerSatelliteImageUuid(state, action) {
      state._changed = true;
      state.dataLayer.satelliteImageUuid = action.payload;
    },
    setDataLayerSatelliteIndex(state, action) {
      state._changed = true;
      state.dataLayer.satelliteIndex = action.payload;
    },
    selectDatasetDataLayer(state, action) {
      state._changed = true;
      state.dataLayer.datasetUuid = action.payload.uuid;
      state.dataLayer.datasetAttribute = action.payload.attribute;
    },
    setDataLayerDatasetAttribute(state, action) {
      state._changed = true;
      state.dataLayer.datasetAttribute = action.payload;
    },
    updateSettings(state, action) {
      state.settings[action.payload.prop] = action.payload.value;
    },
    cloneSatelliteWorkflow(state, action) {
      state._cloned = true;
      state.assetType = AssetType.satelliteImage;
      state.dataLayer.satelliteImageUuid = action.payload.imageUuid;
      state.dataLayer.satelliteIndex = action.payload.index;
      state.settings = {
        ...state.settings,
        ...action.payload.settings,
      };
    },
    cloneDatasetWorkflow(state, action) {
      state._cloned = true;
      state.assetType = action.payload.assetType;
      state.dataLayer.datasetUuid = action.payload.datasetUuid;
      state.dataLayer.datasetAttribute = action.payload.attribute;
      state.settings = {
        ...state.settings,
        ...action.payload.settings,
      };
    },
  },
});

export default createSingleLayerAnalysisSlice.reducer;

export const {
  goToSettings,
  reset,
  goBackFromDataLayers,
  setSelectedAssetType,
  setDataLayerSatelliteImageUuid,
  setDataLayerSatelliteIndex,
  selectDatasetDataLayer,
  setDataLayerDatasetAttribute,
  updateSettings,
  cloneSatelliteWorkflow,
  cloneDatasetWorkflow,
} = createSingleLayerAnalysisSlice.actions;
