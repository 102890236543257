import i18n from 'i18next';

import {
  AreaUnit,
  UserRole,
  JOHN_DEERE_EMAIL_DOMAINS,
} from '../constants/user';

export const getI18nAreaAmount = (anAreaUnit) => {
  let unitPath = 'acres-amount';

  if (anAreaUnit === AreaUnit.hectares) {
    unitPath = 'hectares-amount';
  }

  const unit = i18n.t(`general.area-unit.${unitPath}`);

  return unit;
};

export const getUserOrganizationRole = ({ users }, userUuid) => {
  const user = users.find((u) => u.userUuid === userUuid);
  let result;

  if (user) {
    result = user.allFarms ? UserRole.Advisor : UserRole.User;
  }

  return result;
};

export const isJohnDeereEmail = (email) => {
  return JOHN_DEERE_EMAIL_DOMAINS.some((domain) => email?.endsWith(domain));
};
