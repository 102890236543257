import i18n from 'i18next';

import { GeoMapTypeOption } from '../../../features/satelliteImages/helpers/constants/geoMapType';
import { DatasetViewType, VIEW_TO_I18N_LABEL } from '../../constants/entities/dataset';
import { DEFAULT_GEOMAP, GEOMAP_CONTROL_ITEMS } from '../../constants/entities/equationMap';
import { TransformedAsset } from '../../types';
import {
  isDataset,
  isEquationMap,
  isField,
  isSatelliteImage,
  isSoilDataset,
  isTopographyMap,
  isVectorAnalysis,
} from '../entities/assets';
import {
  getDatasetAttributeNameHandler,
  getDatasetGeoMapName,
  getDatasetLegendColors,
  getDatasetViewProps,
  getFullAttribute,
  getUnitLabel,
} from '../entities/dataset';
import {
  getSatelliteImageViewProps,
  isNoContrastData,
  isNoCropData,
  isPlanetImage,
  isRawDisabled,
} from '../entities/satelliteImage';
import { comparator } from '../utils/string';
import { getValueOptionsArray } from '../components/uiList';
import { TransformedSatelliteImage } from '../../../features/satelliteImages/types/satelliteImage';
import { getSatelliteImageLegendColors } from '../../../features/satelliteImages/helpers/functions/ui';
import { AssetGroupType } from '../../constants/entities/asset';
import { formatAcquisitionDate } from '../../satellite';
import { TransformedDataset, TransformedTopographyMap } from '../../types/dataset';
import { AreaUnit } from '../../../features/user/helpers/constants/user';
import {
  convertAttributeToGeoMapName,
  getTopographyMapAttributeNameHandler,
  getTopographyMapViewProps,
} from '../entities/topographyMap';
import {
  DatasetMapParams,
  DatasetViewControlConfig,
  MiniLegendControlConfig,
  SatelliteImageMapParams,
} from '../../types/map';
import {
  getDatasetOverviewItems,
  getDatasetStatisticsItems,
  getSatelliteOverviewItems,
} from './legend';
import { getDatasetMapAttributeAndViewType } from './getDatasetMapAttributeAndViewType';

export const getOverlayLayersControlsConfig = (enabled = false) => {
  return {
    enabled,
  };
};

export const getShowHideControlConfig = (item?: TransformedAsset | null, enabled = true) => {
  return {
    enabled: (item && 'geoMaps' in item) ? enabled : false,
  };
};

export const getLegendControlConfig = (item?: TransformedAsset | null, enabled = true) => {
  if (!item || (!isVectorAnalysis(item) && !isEquationMap(item))) {
    return {
      enabled: false,
    };
  }

  return {
    enabled: item.geoMaps ? enabled : false,
  };
};

export const getScoutingAndSamplingControlConfig = (item?: TransformedAsset | null, enabled = true) => {
  const config = {
    enabled: false,
  };

  if (!item) {
    return config;
  }

  if (isField(item) || isSoilDataset(item) || isVectorAnalysis(item)) {
    config.enabled = item.geoMaps ? enabled : false;
  }

  return config;
};

const getSatelliteImageToCompare = (
  activeImageUuid = '',
  satelliteImages: TransformedSatelliteImage[] = [],
) => {
  const activeImageIndex = satelliteImages.findIndex(({ uuid }) => uuid === activeImageUuid);
  const prevItems = satelliteImages.slice(activeImageIndex + 1);

  return prevItems.find((item) => !!item.statistics);
};

export const getSatelliteImageMiniLegendControlConfig = (
  image?: TransformedSatelliteImage | null,
  {
    geoMapType,
    isRawType,
    viewType,
    satelliteImages,
  }: SatelliteImageMapParams = {},
) => {
  let config: MiniLegendControlConfig = {
    legend: {
      enabled: false,
    },
    overview: {
      enabled: false,
    },
  };

  if (!image) {
    return config;
  }

  const {
    viewValue: selectedViewValue,
    viewType: selectedViewType,
  } = getSatelliteImageViewProps({
    image,
    geoMapType,
    viewType,
    isRawType,
  });

  if (!selectedViewType) {
    return config;
  }

  const selectedGeoMap = image.geoMaps?.find((geoMap) => {
    return geoMap.shortName === selectedViewValue;
  });

  const imageToCompare = satelliteImages
    ? getSatelliteImageToCompare(image.uuid, satelliteImages)
    : null;

  const legendItems = getSatelliteImageLegendColors(
    selectedGeoMap,
    selectedViewType,
  );
  const overviewItems = getSatelliteOverviewItems(
    selectedViewType,
    image.statistics,
    imageToCompare?.statistics,
  );

  config = {
    legend: {
      enabled: !!legendItems,
      items: legendItems,
    },
    overview: {
      enabled: overviewItems.length !== 0,
      items: overviewItems,
      ...(imageToCompare
        ? {
          compareTo: {
            item: imageToCompare,
            type: AssetGroupType.satelliteImages,
            text: formatAcquisitionDate(
              imageToCompare.satelliteImage.acquisitionDate,
            ),
          },
        }
        : null),
    },
  };

  return config;
};

const getDatasetMiniLegendControlConfig = (
  dataset?: TransformedDataset | null,
  {
    attribute,
    viewType,
    areaUnit,
  }: DatasetMapParams & { areaUnit?: AreaUnit; } = {},
) => {
  let config: MiniLegendControlConfig = {
    legend: {
      enabled: false,
    },
    statistics: {
      enabled: false,
    },
    overview: {
      enabled: false,
    },
  };

  if (!dataset) {
    return config;
  }

  const {
    attribute: processedAttribute,
    view: processedView,
  } = getDatasetViewProps(dataset, attribute, viewType);

  if (!processedAttribute) {
    return config;
  }

  const selectedGeoMap = dataset.geoMaps?.find((geoMap) => {
    return geoMap.shortName === getDatasetGeoMapName(processedView, processedAttribute);
  });
  const legendItems = getDatasetLegendColors(selectedGeoMap);
  const nameHandler = getDatasetAttributeNameHandler(dataset.fullAttributes);
  const fullAttribute = getFullAttribute(processedAttribute, dataset.fullAttributes);
  const statisticsItems = getDatasetStatisticsItems(processedAttribute, dataset.statistics);
  const overviewItems = getDatasetOverviewItems(dataset, areaUnit);

  config = {
    legend: {
      enabled: !!legendItems,
      name: nameHandler(processedAttribute),
      unit: getUnitLabel(fullAttribute?.unit),
      items: legendItems,
    },
    statistics: {
      enabled: !!statisticsItems.length,
      items: statisticsItems,
    },
    overview: {
      enabled: !!overviewItems.length,
      items: overviewItems,
    },
  };

  return config;
};

const getTopographyMapMiniLegendControlConfig = (
  topographyMap?: TransformedTopographyMap | null,
  {
    viewType,
    isCreationView,
  }: {
    viewType?: string;
    isCreationView?: boolean;
  } = {},
) => {
  let config: MiniLegendControlConfig = {
    legend: {
      enabled: false,
    },
  };

  if (!topographyMap) {
    return config;
  }

  const { attribute } = getTopographyMapViewProps(
    topographyMap,
    viewType,
    isCreationView,
  );

  const geoMapName = convertAttributeToGeoMapName(attribute);
  const selectedGeoMap = topographyMap.geoMaps?.find(
    (geoMap) => geoMap.shortName === geoMapName,
  );

  const legendItems = getDatasetLegendColors(selectedGeoMap);
  const nameHandler = getTopographyMapAttributeNameHandler(
    topographyMap.fullAttributes,
  );
  const fullAttribute = getFullAttribute(
    attribute,
    topographyMap.fullAttributes,
  );

  config = {
    legend: {
      enabled: !!legendItems,
      name: nameHandler(attribute),
      unit: getUnitLabel(fullAttribute?.unit),
      items: legendItems,
    },
  };

  return config;
};

export const getMiniLegendControlConfig = (
  item?: TransformedAsset | null,
  {
    soilViewType = null,
    soilAttribute,
    yieldViewType = null,
    yieldAttribute,
    asAppliedViewType = null,
    asAppliedAttribute,
    topographyMapViewType,
    satelliteGeoMapType,
    satelliteViewType,
    isRawSatelliteType,
    satelliteImages,
    areaUnit,
    isCreationView,
  }: {
    soilViewType?: DatasetViewType | null,
    soilAttribute?: string,
    yieldViewType?: DatasetViewType | null,
    yieldAttribute?: string,
    asAppliedViewType?: DatasetViewType | null,
    asAppliedAttribute?: string,
    topographyMapViewType?: string,
    satelliteGeoMapType?: GeoMapTypeOption,
    isRawSatelliteType?: boolean,
    satelliteViewType?: string,
    satelliteImages?: TransformedSatelliteImage[],
    areaUnit?: AreaUnit,
    isCreationView?: boolean,
  } = {},
) => {
  if (item && isSatelliteImage(item)) {
    return getSatelliteImageMiniLegendControlConfig(item, {
      geoMapType: satelliteGeoMapType,
      isRawType: isRawSatelliteType,
      viewType: satelliteViewType,
      satelliteImages,
    });
  }

  if (item && isDataset(item)) {
    const { attribute, viewType } = getDatasetMapAttributeAndViewType(item, {
      soilAttribute,
      yieldAttribute,
      asAppliedAttribute,
      soilViewType,
      yieldViewType,
      asAppliedViewType,
    });

    return getDatasetMiniLegendControlConfig(item, {
      attribute,
      viewType,
      areaUnit,
    });
  }

  if (item && isTopographyMap(item)) {
    return getTopographyMapMiniLegendControlConfig(item, {
      viewType: topographyMapViewType,
      isCreationView,
    });
  }

  return {
    legend: {
      enabled: false,
    },
    statistics: {
      enabled: false,
    },
    overview: {
      enabled: false,
    },
  };
};

export const getGeoMapTypeControlConfig = (
  item?: TransformedAsset | null,
  {
    satelliteGeoMapType,
    isRawSatelliteType,
  }: {
    satelliteGeoMapType?: GeoMapTypeOption,
    isRawSatelliteType?: boolean,

  } = {},
) => {
  if (item && isSatelliteImage(item) && item.geoMaps) {
    return {
      enabled: true,
      cropHidden: isPlanetImage(item),
      cropDisabled: isPlanetImage(item) || isNoCropData(item, isRawSatelliteType),
      contrastDisabled: isNoContrastData(item, isRawSatelliteType) || !item?.statistics,
      rawDisabled: isRawDisabled(item, satelliteGeoMapType),
      value: satelliteGeoMapType,
    };
  }

  return {
    enabled: false,
  };
};

export const getGeoMapsControlConfig = (
  item?: TransformedAsset | null,
  {
    equationMapGeoMap,
  }: {
    equationMapGeoMap?: string;
  } = {},
) => {
  if (item && isEquationMap(item) && item.geoMaps) {
    return {
      enabled: true,
      items: GEOMAP_CONTROL_ITEMS,
      selectedItem: equationMapGeoMap || DEFAULT_GEOMAP,
    };
  }

  return {
    enabled: false,
  };
};

const getDatasetViewControlConfig = (
  item?: TransformedAsset | null,
  {
    attribute,
    viewType,
    datasetAttributesEnabled = true,
  }: DatasetMapParams & {
    datasetAttributesEnabled?: boolean;
  } = {},
) => {
  let config: DatasetViewControlConfig = {
    enabled: false,
  };

  if (!item || !isDataset(item)) {
    return config;
  }

  const {
    attributes: processedAttributes,
    attribute: processedAttribute,
    views: processedViews,
    view: processedView,
  } = getDatasetViewProps(item, attribute, viewType);

  if (!processedAttribute) {
    return config;
  }

  const nameHandler = getDatasetAttributeNameHandler(item.fullAttributes);

  const attributes = datasetAttributesEnabled
    ? getValueOptionsArray(processedAttributes, nameHandler)
      .sort((aAttr: { title: string }, bAttr: { title: string }) => {
        return comparator(aAttr.title, bAttr.title);
      })
    : [];

  const views = getValueOptionsArray(
    processedViews,
    (v: DatasetViewType) => i18n.t(VIEW_TO_I18N_LABEL[v]),
  );

  config = {
    enabled: true,
    data: {
      attributes,
      selectedAttribute: processedAttribute,
      views,
      selectedView: processedView,
    },
  };

  return config;
};

export const getViewControlConfig = (
  item?: TransformedAsset | null,
  {
    soilViewType = null,
    soilAttribute,
    yieldViewType = null,
    yieldAttribute,
    asAppliedViewType = null,
    asAppliedAttribute,
    datasetAttributesEnabled,
  }: {
    soilViewType?: DatasetViewType | null,
    soilAttribute?: string,
    yieldViewType?: DatasetViewType | null,
    yieldAttribute?: string,
    asAppliedViewType?: DatasetViewType | null,
    asAppliedAttribute?: string,
    datasetAttributesEnabled?: boolean,
  } = {},
) => {
  let config: DatasetViewControlConfig = {
    enabled: false,
  };

  if (!item || !isDataset(item)) {
    return config;
  }

  const { attribute, viewType } = getDatasetMapAttributeAndViewType(item, {
    soilAttribute,
    yieldAttribute,
    asAppliedAttribute,
    soilViewType,
    yieldViewType,
    asAppliedViewType,
  });

  config = getDatasetViewControlConfig(item, {
    attribute,
    viewType,
    datasetAttributesEnabled,
  });

  return config;
};

export const getIndexesControlConfig = (
  item?: TransformedAsset | null,
  {
    topographyMapViewType,
    topographyViewTypeControlEnabled = false,
    satelliteGeoMapType,
    isRawSatelliteType,
    satelliteViewType,
    isCreationView = false,
  }: {
    topographyMapViewType?: string,
    topographyViewTypeControlEnabled?: boolean,
    satelliteGeoMapType?: GeoMapTypeOption,
    isRawSatelliteType?: boolean,
    satelliteViewType?: string;
    isCreationView?: boolean,
  } = {},
) => {
  if (item && isTopographyMap(item)) {
    const {
      attributes,
      attribute,
    } = getTopographyMapViewProps(item, topographyMapViewType, isCreationView);

    const nameHandler = getTopographyMapAttributeNameHandler(item.fullAttributes);

    return {
      enabled: !isCreationView || topographyViewTypeControlEnabled,
      items: getValueOptionsArray(attributes, nameHandler),
      selectedItem: attribute,
    };
  }

  if (item && isSatelliteImage(item)) {
    const {
      viewTypes,
      viewType,
    } = getSatelliteImageViewProps({
      image: item,
      geoMapType: satelliteGeoMapType,
      viewType: satelliteViewType,
      isRawType: isRawSatelliteType,
    });

    return {
      enabled: true,
      items: getValueOptionsArray(viewTypes, (vt: string) => vt.toUpperCase()),
      selectedItem: viewType,
    };
  }

  return {
    enabled: false,
  };
};
