import { TransformedField } from '../../../features/field/types/field';
import { PinsGroup } from '../../../features/pins/types';
import { TransformedSatelliteImage } from '../../../features/satelliteImages/types/satelliteImage';
import { AssetLoadStatus, AssetType } from '../../constants/entities/asset';
import { AnalysisMapType, ZonesOperationType } from '../../constants/entities/vectorAnalysisMap';
import { formatAcquisitionDate } from '../../satellite';
import type { TransformedAsset } from '../../types';
import {
  TransformedAsAppliedDataset,
  TransformedDataset,
  TransformedSoilDataset,
  TransformedTopographyMap,
  TransformedYieldDataset,
} from '../../types/dataset';
import { TransformedEquationMap } from '../../types/equationMap';
import { TransformedThreeDimensionalMap } from '../../types/threeDimensionalMap';
import { TransformedVectorAnalysisMap } from '../../types/vectorAnalysisMap';

export const isAssetAvailable = (assetStatus: AssetLoadStatus): boolean => {
  return assetStatus !== AssetLoadStatus.noData;
};

export const isAssetRequested = (assetStatus: AssetLoadStatus | null): boolean => {
  return assetStatus !== AssetLoadStatus.idle;
};

export const isField = (node: TransformedAsset): node is TransformedField => (
  node && node._type === AssetType.field
);

export const isSatelliteImage = (node: TransformedAsset | null): node is TransformedSatelliteImage => (
  !!node && node._type === AssetType.satelliteImage
);

export const isSoilDataset = (node: TransformedAsset | null): node is TransformedSoilDataset => (
  !!node && node._type === AssetType.soilDataset
);

export const isYieldDataset = (node: TransformedAsset | null): node is TransformedYieldDataset => (
  !!node && node._type === AssetType.yieldDataset
);

export const isAsAppliedDataset = (node: TransformedAsset | null): node is TransformedAsAppliedDataset => (
  !!node && node._type === AssetType.asAppliedDataset
);

export const isDataset = (node: TransformedAsset | null): node is (
  TransformedSoilDataset
  | TransformedYieldDataset
  | TransformedAsAppliedDataset
) => (
  !!node && (
    isSoilDataset(node) || isYieldDataset(node) || isAsAppliedDataset(node)
  )
);

export const isTopographyMap = (node: TransformedAsset | null): node is TransformedTopographyMap => (
  !!node && node._type === AssetType.topographyMap
);

export const is3dMap = (node: TransformedAsset | null): node is TransformedThreeDimensionalMap => (
  !!node && node._type === AssetType.threeDimensionalMap
);

export const isEquationMap = (node: TransformedAsset | null): node is TransformedEquationMap => (
  !!node && node._type === AssetType.equationMap
);

export const isVectorAnalysis = (node: TransformedAsset | null): node is TransformedVectorAnalysisMap => (
  !!node && node._type === AssetType.vectorAnalysisMap
);

export const isPinsGroup = (node: TransformedAsset | null): node is PinsGroup => (
  !!node && node._type === AssetType.pinsGroup
);

export const areSatelliteImages = (nodes: TransformedAsset[]): nodes is TransformedSatelliteImage[] => {
  return nodes.some(isSatelliteImage);
};

export const areSoilDatasets = (nodes: TransformedAsset[]): nodes is TransformedSoilDataset[] => {
  return nodes.some(isSoilDataset);
};

export const areYieldDatasets = (nodes: TransformedAsset[]): nodes is TransformedYieldDataset[] => {
  return nodes.some(isYieldDataset);
};

export const areAsAppliedDatasets = (nodes: TransformedAsset[]): nodes is TransformedAsAppliedDataset[] => {
  return nodes.some(isAsAppliedDataset);
};

export const areDatasets = (nodes: TransformedAsset[]): nodes is TransformedDataset[] => {
  return nodes.some(isDataset);
};

export const areTopographyMaps = (nodes: TransformedAsset[]): nodes is TransformedTopographyMap[] => {
  return nodes.some(isTopographyMap);
};

export const isDrawnManuallyVectorAnalysis = (node: TransformedAsset): boolean => {
  let result = false;

  if (isVectorAnalysis(node)) {
    result = node.analysisType === AnalysisMapType.drawn;
  }

  return result;
};

export const isZonesOperationsIntersectVectorAnalysis = (node: TransformedAsset) => {
  let result = false;

  if (isVectorAnalysis(node)) {
    result = node.analysisType === AnalysisMapType.zoneOperations
      && node.parameters?.zonesOperation === ZonesOperationType.intersect;
  }

  return result;
};

export const isMultiLayerVectorAnalysis = (node: TransformedAsset) => {
  let result = false;

  if (isVectorAnalysis(node)) {
    result = node.analysisType === AnalysisMapType.multilayers;
  }

  return result;
};

export const isSatelliteVectorAnalysis = (node: TransformedAsset) => {
  let result = false;

  if (isVectorAnalysis(node)) {
    result = node.analysisType === AnalysisMapType.satellite;
  }

  return result;
};

export const isSoilVectorAnalysis = (node: TransformedAsset) => {
  let result = false;

  if (isVectorAnalysis(node)) {
    result = node.analysisType === AnalysisMapType.soil;
  }

  return result;
};

export const isYieldVectorAnalysis = (node: TransformedAsset) => {
  let result = false;

  if (isVectorAnalysis(node)) {
    result = node.analysisType === AnalysisMapType.yield;
  }

  return result;
};

export const isAsAppliedVectorAnalysis = (node: TransformedAsset) => {
  let result = false;

  if (isVectorAnalysis(node)) {
    result = node.analysisType === AnalysisMapType.asApplied;
  }

  return result;
};

export const isTopographyVectorAnalysis = (node: TransformedAsset) => {
  let result = false;

  if (isVectorAnalysis(node)) {
    result = node.analysisType === AnalysisMapType.topography;
  }

  return result;
};

export const getAssetName = (asset: TransformedAsset): string => {
  let name: string;

  if (isSatelliteImage(asset)) {
    name = formatAcquisitionDate(asset.satelliteImage.acquisitionDate);
  } else {
    name = asset.name || '';
  }

  return name;
};
