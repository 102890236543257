import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../../app/store/helpers/functions';
import {
  activateImage,
  selectImages,
  setImagesSelection,
  unselectImages,
} from '../../../createStabilityZonesSlice';
import useDataLayersList from '../../../../dataLayersView/hooks/useDataLayersList';
import { selectCreateAnalysisSatelliteImages } from '../../../../../field/fieldSelectors';
import { selectActiveUuid, selectSelectedUuids } from '../../../createStabilityZonesSelectors';
import CreateAnalysisSatelliteImagesPanel from '../../../../dataLayersView/components/DataLayersList/Panels/CreateAnalysisSatelliteImagesPanel';
import { DEFAULT_PREVIEW_INDEX } from '../../../../../../helpers/satellite';
import { AssignableAsset } from '../../../../dataLayersView/types/dataLayersList';

export default function DataLayersPanel() {
  const dispatch = useAppDispatch();

  const satelliteImages = useAppSelector(selectCreateAnalysisSatelliteImages);
  const selectedSatelliteImageUuid = useAppSelector(selectActiveUuid);
  const checkedSatelliteImagesUuids = useAppSelector(selectSelectedUuids);

  const handleImageActivate = (asset: AssignableAsset) => {
    dispatch(activateImage(asset.uuid));
  };

  const handleImageSelectionToggle = (value: boolean, uuid: string) => {
    if (value) {
      dispatch(selectImages([uuid]));
    } else {
      dispatch(unselectImages([uuid]));
    }
  };

  const handleAllSelectionToggle = (value: boolean, uuids: string[]) => {
    if (value) {
      dispatch(selectImages(uuids));
    } else {
      dispatch(unselectImages(uuids));
    }
  };

  const handleRecommendedSelect = (uuids: string[]) => {
    dispatch(setImagesSelection(uuids));
  };

  const panels = [
    <CreateAnalysisSatelliteImagesPanel
      key="top-panel"
      selectedIndex={DEFAULT_PREVIEW_INDEX}
      onRecommendedSelect={handleRecommendedSelect}
    />,
  ];

  const {
    dataLayersListComponent,
  } = useDataLayersList({
    collapsible: true,
    mode: 'multiSelect',
    assets: satelliteImages,
    selectedUuid: selectedSatelliteImageUuid,
    checkedUuids: checkedSatelliteImagesUuids,
    panels,
    onAssetClick: handleImageActivate,
    onAssetCheckboxClick: handleImageSelectionToggle,
    onAllCheckboxClick: handleAllSelectionToggle,
  });

  return dataLayersListComponent;
}
