import i18n from 'i18next';

import {
  ATTRIBUTE_UNIT_TO_I18N_LABEL,
  UNTRANSLATABLE_ATTRIBUTE_UNITS,
} from '../../constants/units/attributeUnit';
import {
  FullAttribute,
  TransformedDataset,
} from '../../types/dataset';
import { comparator } from '../utils/string';
import { DatasetViewType } from '../../constants/entities/dataset';
import { GeoMap } from '../../types/api';
import { convertNumberToFormattedString } from '../../markup';
import { findAttributeToPreselect } from './attribute';

/**
 * Extracts the attributes from a dataset that are associated with geo maps.
 *
 * @param {TransformedDataset} dataset The transformed dataset with attributes and geo maps.
 * @returns {string[]} An array of attributes associated with geo maps.
 */
export const extractDatasetGeoMapAttributes = ({
  attributes = [],
  geoMaps = [],
}: TransformedDataset): string[] => {
  const geoMapsNames = geoMaps.map(({ shortName }) => shortName);

  return attributes.filter((attribute) => {
    return geoMapsNames.some((name) => {
      return name.indexOf(attribute) !== -1;
    });
  });
};

export const addAttributesAndSortDatasets = (datasets: TransformedDataset[]) => {
  return (datasets || [])
    .map((dataset) => {
      return {
        ...dataset,
        attributes: extractDatasetGeoMapAttributes(dataset),
      };
    })
    .sort((a, b) => comparator(a.name || '', b.name || ''));
};

// @ts-expect-error
export const getUnitLabel = (unit) => {
  if (UNTRANSLATABLE_ATTRIBUTE_UNITS.includes(unit)) {
    return '';
  }

  // @ts-expect-error
  const i18nLabel = ATTRIBUTE_UNIT_TO_I18N_LABEL[unit];

  return i18nLabel
    ? i18n.t(i18nLabel)
    : unit;
};

export const getDatasetViewTypes = (dataset: TransformedDataset): DatasetViewType[] => {
  const result = [DatasetViewType.raw];
  const hasProcessedViews = (dataset.geoMaps || []).some((geoMap) => {
    return geoMap.shortName.startsWith(DatasetViewType.processed);
  });

  if (hasProcessedViews) {
    result.push(DatasetViewType.processed);
  }

  return result;
};

export const getDatasetGeoMapName = (
  view: DatasetViewType,
  attribute: string,
  isThumbnail?: boolean,
) => {
  const prefix = isThumbnail ? 'thumbnail_' : '';

  return `${prefix}${view}_${attribute}`;
};

export const getDatasetViewProps = (
  dataset: TransformedDataset,
  attribute?: string | null,
  view?: DatasetViewType | null,
) => {
  const views = getDatasetViewTypes(dataset);
  const geoMapByShortName = Object.fromEntries(
    (dataset.geoMaps || []).map((geoMap) => [geoMap.shortName, geoMap]),
  );
  const selectedView = view || DatasetViewType.raw;
  const attributesWithGeoMaps = (dataset.attributes || []).filter((attr) => {
    return !!geoMapByShortName[getDatasetGeoMapName(selectedView, attr)];
  });
  const selectedGeoMapName = getDatasetGeoMapName(selectedView, attribute || '');
  const selectedGeoMapExist = !!geoMapByShortName[selectedGeoMapName];
  const selectedAttribute = selectedGeoMapExist && attribute
    ? attribute
    : findAttributeToPreselect(dataset) || attributesWithGeoMaps[0] || '';

  return {
    attributes: attributesWithGeoMaps,
    attribute: selectedAttribute,
    views,
    view: selectedView,
  };
};

export const getFullAttribute = (attribute: string, fullAttributes?: FullAttribute[] | null) => {
  return (fullAttributes || []).find((fullAttribute) => {
    return fullAttribute.transliteratedName === attribute;
  });
};

export const getDatasetAttributeFullName = (attribute: string, fullAttributes?: FullAttribute[] | null) => {
  return getFullAttribute(attribute, fullAttributes)?.fullName || attribute;
};

export const getDatasetAttributeNameHandler = (fullAttributes?: FullAttribute[] | null) => {
  return (attribute: string) => {
    return getDatasetAttributeFullName(attribute, fullAttributes);
  };
};

export const getDatasetThumbnailGeoMap = ({ geoMaps, view, attribute }: {
  geoMaps?: GeoMap[],
  view: DatasetViewType,
  attribute: string,
}): GeoMap | null => {
  const geoMapName = getDatasetGeoMapName(view, attribute, true);
  const geoMap = (geoMaps || []).find(({ shortName }) => shortName === geoMapName);

  return geoMap || null;
};

export const getDatasetLegendColors = (geoMap?: GeoMap): {
  color: string;
  quantity: string;
}[] | null => {
  if (!geoMap) {
    return null;
  }

  return (geoMap.classes || []).map((classValue, index) => {
    return {
      color: geoMap.hexColorCodes?.[index] || '',
      quantity: convertNumberToFormattedString(parseFloat(classValue.toFixed(3)).toString()) || '',
    };
  });
};
