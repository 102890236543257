export const HELPER_TEXTS = {
  emailExists: 'An account with the given email already exists.',
  passwordLength: 'Please use 8+ characters for secure password.',
  passwordNumeric: 'Password must contain a number.',
  passwordStartEndSpaces: 'Password cannot start or end with a space.',
  passwordsMismatch: 'Passwords do not match.',
  invalidEmail: 'The email you entered is incorrect.',
  notVerifiedEmail: 'The email is not verified. When you registered, a verification email was sent. Please follow the instructions in that email to verify your address',
  invalidPhone: 'The phone you entered is incorrect.',
  invalidPassword: 'The password you entered is invalid.',
  invalidCode: 'The verification code you entered is invalid.',
  expiredCode: 'The verification code you entered is expired. Please request a code again.',
};

export enum HelperTextType {
  error = 'error',
  success = 'success',
  info = 'info',
}
