import React from 'react';
import { useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';

import { CONFIG } from './config';
import {
  selectDatasetUuid,
  selectIsProcessing,
  selectStep,
  selectTab,
} from '../../cleanCalibrateSelectors';
import {
  reset,
  setCalibrateCalibrationAttributes,
  setCalibratePathwiseCalibrationBasis,
  setCleanTargetAttribute,
} from '../../cleanCalibrateSlice';
import ProcessingTypesStepContent from '../ProcessingTypesStepContent';
import useDidMount from '../../../../../hooks/useDidMount';
import BackdropLoading from '../../../../../components/Loading/BackdropLoading';
import {
  findYieldAttributes,
  getAttributeString,
  findMachineIdAttribute,
} from '../../helpers/functions/attributes';
import { findYieldAttribute } from '../../../../../helpers/functions/entities/attribute';
import { selectYieldDataset } from '../../../../field/fieldSelectors';
import { useAppSelector } from '../../../../../app/store/helpers/functions';

import './index.scss';

export default function CleanCalibrateYieldPopup({
  onCancel = () => {},
}: {
  onCancel: () => void,
}) {
  const dispatch = useDispatch();
  const tab = useAppSelector(selectTab);
  const step = useAppSelector(selectStep);
  const isProcessing = useAppSelector(selectIsProcessing);
  const datasetUuid = useAppSelector(selectDatasetUuid);
  const dataset = useAppSelector((state) => selectYieldDataset(state, datasetUuid));

  const Content = CONFIG[tab].content;
  const LeftActions = CONFIG[tab].leftActions;
  const RightActions = CONFIG[tab].rightActions;

  useDidMount(() => {
    if (!dataset?.fullAttributes) {
      return;
    }

    const yieldAttributes = findYieldAttributes(dataset.fullAttributes);

    if (yieldAttributes.length !== 0) {
      const yieldAttributesStrings = yieldAttributes.map((fullAttribute) => {
        return getAttributeString(fullAttribute);
      });
      dispatch(setCalibrateCalibrationAttributes(yieldAttributesStrings));
    }

    const machineAttribute = findMachineIdAttribute(dataset.fullAttributes);

    if (machineAttribute) {
      const machineAttributeString = getAttributeString(machineAttribute);
      dispatch(setCalibratePathwiseCalibrationBasis(machineAttributeString));
    }

    const yieldCleanAttribute = findYieldAttribute([...yieldAttributes]);

    if (yieldCleanAttribute) {
      const yieldCleanAttributeString = getAttributeString(yieldCleanAttribute);
      dispatch(setCleanTargetAttribute(yieldCleanAttributeString));
    }
  });

  const handleCancel = () => {
    dispatch(reset());
    onCancel();
  };

  return (
    <Dialog open fullWidth>
      {
        step === 0
          ? <ProcessingTypesStepContent onCancel={handleCancel} />
          : <Content onCancel={handleCancel} />
      }
      <Divider />
      <DialogActions className="clean-calibrate-yield-popup__actions">
        <div className="clean-calibrate-yield-popup__left-actions">
          <LeftActions />
        </div>
        <RightActions onCancel={handleCancel} />
      </DialogActions>
      {
        isProcessing && <BackdropLoading />
      }
    </Dialog>
  );
}
